import { Grid, Row } from '@devstart/react-bootstrap';
import { isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import envData from '../../../config/env.json';
import { getLangCode } from '../../../config/i18n';
import AssinaturaTiagoManchini from '../assets/images/components/assinatura-tiago-manchini';

import { createFlashMessage } from '../components/Flash/redux';
import { Loader, Spacer } from '../components/helpers';
import RedirectHome from '../components/redirect-home';
import { executeGA, fetchProfileForUser, showCert } from '../redux/actions';
import { User, UserFetchState } from '../redux/prop-types';
import {
  isDonatingSelector,
  showCertFetchStateSelector,
  showCertSelector,
  userByNameSelector,
  userFetchStateSelector,
  usernameSelector
} from '../redux/selectors';
import { certMap } from '../resources/cert-and-project-map';
import certificateMissingMessage from '../utils/certificate-missing-message';
import reallyWeirdErrorMessage from '../utils/really-weird-error-message';
import standardErrorMessage from '../utils/standard-error-message';
import TopLeft from '../assets/images/background-top-certificate.png';
import BottomRight from '../assets/images/background-button-certificate.png';
import { generatePDF } from '../utils/download-pdf';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';
import BackButton from '../components/ui/BackButton';

const { clientLocale } = envData;

const localeCode = getLangCode(clientLocale);
type Cert = {
  username: string;
  name: string;
  certName: string;
  certTitle: string;
  completionTime: number;
  date: number;
};
interface ShowCertificationProps {
  cert: Cert;
  certSlug: string;
  createFlashMessage: typeof createFlashMessage;
  executeGA: (payload: Record<string, unknown>) => void;
  fetchProfileForUser: (username: string) => void;
  fetchState: {
    pending: boolean;
    complete: boolean;
    errored: boolean;
  };
  isDonating: boolean;
  isValidCert: boolean;
  location: {
    pathname: string;
  };
  showCert: ({
    username,
    certSlug
  }: {
    username: string;
    certSlug: string;
  }) => void;
  signedInUserName: string;
  user: User;
  userFetchState: UserFetchState;
  username: string;
}

const requestedUserSelector = (state: unknown, { username = '' }) =>
  userByNameSelector(username.toLowerCase())(state) as User;

const validCertSlugs = certMap.map(cert => cert.certSlug);

const mapStateToProps = (state: unknown, props: ShowCertificationProps) => {
  const isValidCert = validCertSlugs.some(slug => slug === props.certSlug);
  return createSelector(
    showCertSelector,
    showCertFetchStateSelector,
    usernameSelector,
    userFetchStateSelector,
    isDonatingSelector,
    requestedUserSelector,
    (
      cert: Cert,
      fetchState: ShowCertificationProps['fetchState'],
      signedInUserName: string,
      userFetchState: UserFetchState,
      isDonating: boolean,
      user: User
    ) => ({
      cert,
      fetchState,
      isValidCert,
      signedInUserName,
      userFetchState,
      isDonating,
      user
    })
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { createFlashMessage, showCert, fetchProfileForUser, executeGA },
    dispatch
  );

const ShowCertification = (props: ShowCertificationProps): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    const { username, certSlug, isValidCert, showCert } = props;
    if (isValidCert) {
      showCert({ username, certSlug });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      signedInUserName,
      cert: { username = '' },
      fetchProfileForUser,
      user
    } = props;

    if (!signedInUserName || signedInUserName !== username) {
      if (isEmpty(user) && username) {
        fetchProfileForUser(username);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.userFetchState,
    props.signedInUserName,
    props.isDonating,
    props.cert,
    props.executeGA
  ]);

  const {
    cert,
    fetchState,
    isValidCert,
    createFlashMessage,
    signedInUserName,
    location: { pathname }
  } = props;

  if (!isValidCert) {
    createFlashMessage(certificateMissingMessage);
    return <RedirectHome />;
  }

  const { pending, complete, errored } = fetchState;

  if (pending) {
    return <Loader fullScreen={true} />;
  }

  if (!pending && errored) {
    createFlashMessage(standardErrorMessage);
    return <RedirectHome />;
  }

  if (!pending && !complete && !errored) {
    createFlashMessage(reallyWeirdErrorMessage);
    return <RedirectHome />;
  }

  const { date, name: userFullName = null, username, certTitle } = cert;

  const displayName = userFullName ?? username;
  const certificateFile = `DEVstart - Conclusão ${certTitle} - ${
    userFullName ?? username
  }`;

  const certDate = new Date(date);
  const certYear = certDate.getFullYear();
  const certMonth = certDate.getMonth();
  const certURL = `https://app.devstart.tech${pathname}`;

  const handleDownloadCert = () => {
    const element = document.getElementById('certificate-item');
    element && generatePDF(element, certificateFile);
  };

  const shareCertBtns = (
    <div className='share-certification'>
      <Button onClick={handleDownloadCert} buttonType={ButtonTypes.Primary}>
        <p className='title-share-certification'>
          BAIXAR ATESTADO DE PARTICIPAÇÃO
        </p>
      </Button>

      <Button
        href={`https://twitter.com/intent/tweet?text=${t('profile.tweet', {
          certTitle: certTitle,
          certURL: certURL
        })}`}
        target='_blank'
        buttonType={ButtonTypes.Light}
      >
        <p className='title-share-certification'>COMPARTILHAR NO X</p>
      </Button>

      <Button
        href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certTitle}&organizationId=399390&issueYear=${certYear}&issueMonth=${
          certMonth + 1
        }&certUrl=${certURL}`}
        target='_blank'
        buttonType={ButtonTypes.Light}
      >
        <p className='title-share-certification'>ADICIONAR AO LINKEDIN</p>
      </Button>
    </div>
  );

  return (
    <Grid className='certificate-outer-wrapper'>
      <div className='back-button-area-certification'>
        <BackButton prevUrl='/certificate-of-participation' />
      </div>

      <div className='certificate-links'>
        {signedInUserName === username ? shareCertBtns : ''}
      </div>

      <Row id='certificate-item' className='certification-namespace'>
        <img
          src={TopLeft}
          alt='top-left'
          width='316px'
          height='202px'
          className='top-left-image'
        />
        <img
          src={BottomRight}
          alt='bottom-left'
          width='316px'
          height='202px'
          className='bottom-right-image'
        />
        <div className='header-certification'>
          {t('certification.issued')}&nbsp;
          {certDate.toLocaleString([localeCode, 'en-US'], {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </div>

        <main className='information'>
          <div className='information-container'>
            <Trans i18nKey='certification.fulltext' title={certTitle}>
              <h3>placeholder</h3>
              <h1>
                <strong>{{ user: displayName }}</strong>
              </h1>
              <h3>placeholder</h3>
              <h1>
                <strong>
                  {{
                    title: t(`certification.title.${certTitle}`, certTitle)
                  }}
                </strong>
              </h1>
              <h4>{{ time: 80 }}</h4>
            </Trans>
          </div>
        </main>
        <footer>
          <div className='row signatures'>
            <AssinaturaTiagoManchini style={{ height: 150, width: 200 }} />
            <p>
              <strong>{t('certification.executive-name')}</strong>
            </p>
            <p>{t('certification.executive')}</p>
          </div>
          <Row>
            <p className='verify'>{t('certification.verify')}</p>
            <p className='verify-url'>
              {t('certification.verify-certURL', { certURL: certURL })}
            </p>
          </Row>
          <Spacer size={'small'} />
        </footer>
      </Row>
    </Grid>
  );
};

ShowCertification.displayName = 'ShowCertification';

export default connect(mapStateToProps, mapDispatchToProps)(ShowCertification);
